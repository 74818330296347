import clsx from 'clsx';
import {LandingPageContent} from './landing-page-content';
import {Navbar} from '@common/ui/navigation/navbar/navbar';
import {Button, ButtonProps} from '@common/ui/buttons/button';
import {MixedImage} from '@common/ui/images/mixed-image';
import {Trans} from '@common/i18n/trans';
import {Link} from 'react-router-dom';
import {createSvgIconFromTree} from '@common/icons/create-svg-icon';
import {MenuItemConfig} from '@common/core/settings/settings';
import React, {Fragment} from 'react';
import {DefaultMetaTags} from '@common/seo/default-meta-tags';
import {useTrans} from '@common/i18n/use-trans';
import {useSettings} from '@common/core/settings/use-settings';
import "./new-landing.css";
import {Logo} from "@common/ui/navigation/navbar/logo";
import {useIsDarkMode} from "@common/ui/themes/use-is-dark-mode";
import {
  useLightThemeVariables
} from "@common/ui/themes/use-light-theme-variables";
import {
  useDarkThemeVariables
} from "@common/ui/themes/use-dark-theme-variables";
interface ContentProps {
  content: LandingPageContent;
}
export function NewLandingPage() {
  const settings = useSettings();
  const isDarkMode = useIsDarkMode();
  const lightThemeVars = useLightThemeVariables();
  const darkThemeVars = useDarkThemeVariables();
  const queryParameters = new URLSearchParams(window.location.search)
  const sponsor = queryParameters.get("id");

  let color = lightThemeVars?.['--be-navbar-color'] ?? 'primary';
  let logoColor:'dark' | 'light' = 'light';

  if (isDarkMode) {
    color = darkThemeVars?.['--be-navbar-color'] ?? 'bg-alt';
    logoColor = 'dark';
  }

  const url = sponsor ? "https://"+sponsor+".gotbackup.com/signup.php?p=" : "https://gotbackup.com/signup.php?p=";
  const homepage = settings.homepage as {appearance: LandingPageContent};

  return (
    <Fragment>
      <DefaultMetaTags />
      <div>
        <div className="flex flex-col banner">
          <header>
            <div className="bg-transparent text-white h-64 py-8 flex-shrink-0">
              <div className="flex h-full items-center justify-end gap-10 pl-14 pr-8 md:pl-20 md:pr-20">
                <Logo isDarkMode={isDarkMode} color={color} logoColor={logoColor} sponsor={sponsor ?? undefined}/>

                <div className="ml-auto flex items-center gap-4 md:gap-14">
                  <div className="text-sm max-md:hidden">
                    <a href="/login" type="button"
                       className="focus-visible:ring text-on-primary bg-primary border border-primary hover:bg-primary-dark hover:border-primary-dark disabled:text-disabled disabled:bg-disabled disabled:border-transparent disabled:shadow-none shadow-md whitespace-nowrap inline-flex align-middle flex-shrink-0 items-center transition-button duration-200 select-none appearance-none no-underline outline-none disabled:pointer-events-none disabled:cursor-default rounded-button justify-center font-semibold text-sm h-36 px-18">Login</a>
                  </div>
                </div>
              </div>
            </div>
          </header>
          <div className="content">
            <div className="container mx-auto px-14">
              <h1 className="text-center heading font-bold">
                <Trans message={"GET 6TB OF STORAGE AND 6 SEPARATE ACCOUNTS FOR "} />
                <span className='text-primary'><Trans message={"JUST $9.97 PER MONTH"}/></span>
              </h1>
              <h3 className="text-center md:text-3xl text-lg my-20">
                <Trans message={"Your data "} />
                <strong><Trans message={"SECURITY"} /></strong>
                <Trans message={" is our top priority. We are encrypted end to end."} />
              </h3>

              <div className="video-wrap mx-auto ">
                <div
                  className="video-title text-center flex justify-center text-2xl">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                       fill="currentColor" className="bi bi-play-circle mr-8"
                       viewBox="0 0 16 16">
                    <path
                      d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                    <path
                      d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445"/>
                  </svg>
                  <Trans message={"Learn More in the Video Below!"} />
                </div>
                <div className="video ratio ratio-16x9">
                  <iframe
                    src="https://www.gvovideo.com/v2.php?v=enZd3MsNbKi&amp;a=false&amp;n=false"
                  ></iframe>
                </div>
              </div>

              <div className="md:flex justify-center my-30 securety">
                <img src="/images/new-landing/digicert-icon.png" alt="" />
                <img src="/images/new-landing/ssl-icon.png" alt="" />
                <img src="/images/new-landing/aes256-secured.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="py-40">
          <div className="container mx-auto px-14">
            <h2 className="md:text-4xl text-2xl font-bold text-center mb-40">
              <Trans message={"We believe that you and only you should control your data."} />
            </h2>

            <div className="md:flex gap-24 justify-between border-b pb-30">
              <div className="px-10">
                <img src="/images/new-landing/store-digital-assets-securely.jpg" alt=""
                     className="mx-auto" />
                  <h3 className="md:text-2xl text-xl font-bold my-30">
                    <Trans message={"Store all your digital assets securely."} />
                  </h3>
                  <p className="text-justify text-lg">
                    <Trans message={"Access your files anywhere, and share with complete encrypted control. Access your files from your Windows, Mac as well as from iOS or Android smartphones or tablets. You can also login from any web browser securely."} />
                  </p>
              </div>
              <div className="px-10">
                <img src="/images/new-landing/store-digital-assets-smartly.jpg" alt=""
                     className="mx-auto" />
                  <h3 className="md:text-2xl text-xl font-bold my-30">
                    <Trans message={"You control your data."} /><br /><Trans message={"Not us!"} />
                  </h3>
                  <p className="text-justify text-lg">
                    <Trans message={"We securely store and encrypt your data on its journey to and within the cloud, ensuring that only you and those you authorize can access it. Furthermore, we do not claim ownership of any of your data. Because it's your data."} />
                  </p>
              </div>
              <div className="px-10">
                <img src="/images/new-landing/auto-sync-data.png" alt="" className="mx-auto" />
                  <h3 className="md:text-2xl text-xl font-bold my-30">
                    <Trans message={"Auto-sync past, current, and future data."} />
                  </h3>
                  <p className="text-justify text-lg">
                    <Trans message={"Let your data auto sync and be saved in a highly secure encrypted space and be sure that only you can see your favorite moments and memories. While putting custom encrypted share links in your control."} />
                  </p>
              </div>
            </div>

            <h2
              className="md:text-4xl text-2xl text-uppercase text-center font-bold my-30 pt-20">
              <Trans message={"DATA STORAGE ACCOUNT FEATURES"} />
            </h2>
            <div className="md:flex gap-24 border-b pb-30 features">
              <div className="column">
                <div className="flex items-center p-20 mb-20 box">
                  <div className="icon mr-14">
                    <svg xmlns="http://www.w3.org/2000/svg" width="40"
                         height="40" fill="currentColor" className="bi bi-bag"
                         viewBox="0 0 16 16">
                      <path fill="currentcolor"
                            d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1m3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z"/>
                    </svg>
                  </div>
                  <div>
                    <h3
                      className="md:text-2xl text-xl font-bold text-uppercase">
                      <Trans message={"1TB - 6TB - 20TB STORAGE PLANS"} />
                    </h3>
                    <p className="text-lg">
                      <Trans message={"Backup all your photos, videos, documents & more. Set up unlimited devices."} />
                    </p>
                  </div>
                </div>
                <div className="flex items-center p-20 mb-20 box">
                  <div className="icon mr-14">
                    <svg xmlns="http://www.w3.org/2000/svg" width="40"
                         height="40" fill="currentColor"
                         className="bi bi-cloud-download" viewBox="0 0 16 16">
                      <path
                        d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383"/>
                      <path
                        d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708z"/>
                    </svg>
                  </div>
                  <div>
                    <h3
                      className="md:text-2xl text-xl font-bold text-uppercase">
                      <Trans message={"ACCESS FILES ANYWHERE"} />
                    </h3>
                    <p className="text-lg">
                      <Trans message={"Log in to view all of your files online, from your PC, laptop or smartphone."} />
                    </p>
                  </div>
                </div>
                <div className="flex items-center px-20 py-30 mb-20 box">
                  <div className="icon mr-14">
                    <svg xmlns="http://www.w3.org/2000/svg" width="40"
                         height="40" fill="currentColor"
                         className="bi bi-stopwatch" viewBox="0 0 16 16">
                      <path
                        d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5z"/>
                      <path
                        d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64l.012-.013.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5M8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3"/>
                    </svg>
                  </div>
                  <div>
                    <h3
                      className="md:text-2xl text-xl font-bold text-uppercase">
                      <Trans message={"SUPER FAST ENCRYPTED TRANSFERS"} />
                    </h3>
                    <p className="text-lg">
                      <Trans message={"256-bit AES encryption and SSL/TLS protocols to secure data at rest and in transit."} />
                    </p>
                  </div>
                </div>
                <div className="flex items-center p-20 mb-20 box">
                  <div className="icon mr-14">
                    <svg xmlns="http://www.w3.org/2000/svg" width="40"
                         height="40" fill="currentColor"
                         className="bi bi-laptop" viewBox="0 0 16 16">
                      <path
                        d="M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5"/>
                    </svg>
                  </div>
                  <div>
                    <h3
                      className="md:text-2xl text-xl font-bold text-uppercase">
                      <Trans message={"MAC AND WINDOWS"} />
                    </h3>
                    <p className="text-lg">
                      <Trans message={"GotBackup works on both Mac and Windows PC, laptops."} />
                      <br />
                    </p>
                    <div className="flex">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20"
                           height="20" fill="currentColor"
                           className="bi bi-apple mr-4" viewBox="0 0 16 16">
                        <path
                          d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282"/>
                        <path
                          d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282"/>
                      </svg>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20"
                           height="20" fill="currentColor"
                           className="bi bi-windows" viewBox="0 0 16 16">
                        <path
                          d="M6.555 1.375 0 2.237v5.45h6.555zM0 13.795l6.555.933V8.313H0zm7.278-5.4.026 6.378L16 16V8.395zM16 0 7.33 1.244v6.414H16z"/>
                      </svg>
                    </div>
                  </div>
                </div>
                <div className=" p-20 mb-20 box">
                  <div className="flex items-center mb-30 ">
                    <div className="icon mr-14">
                      <svg xmlns="http://www.w3.org/2000/svg" width="40"
                           height="40" fill="currentColor"
                           className="bi bi-file-text" viewBox="0 0 16 16">
                        <path
                          d="M5 4a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm-.5 2.5A.5.5 0 0 1 5 6h6a.5.5 0 0 1 0 1H5a.5.5 0 0 1-.5-.5M5 8a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1z"/>
                        <path
                          d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1"/>
                      </svg>
                    </div>
                    <div>
                      <h3
                        className="md:text-2xl text-xl font-bold text-uppercase">
                        <Trans message={"DOCUMENT COLLABORATION"} />
                      </h3>
                      <p className="text-lg">
                        <Trans message={"Create docs, notes and power points. Use share links for total collaboration."} />
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center   ">
                    <div className="icon mr-14">
                      <svg xmlns="http://www.w3.org/2000/svg" width="40"
                           height="40" fill="currentColor"
                           className="bi bi-file-image" viewBox="0 0 16 16">
                        <path
                          d="M8.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
                        <path
                          d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M3 2a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v8l-2.083-2.083a.5.5 0 0 0-.76.063L8 11 5.835 9.7a.5.5 0 0 0-.611.076L3 12z"/>
                      </svg>
                    </div>
                    <div>
                      <h3
                        className="md:text-2xl text-xl font-bold text-uppercase">
                        <Trans message={"PHOTO COLLABORATION"} />
                      </h3>
                      <p className="text-lg">
                        <Trans message={"Create albums and enjoy the enhanced photo editor. Use share links to share your photos or albums."} />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="flex items-center p-20 mb-20 box">
                  <div className="icon mr-14">
                    <svg xmlns="http://www.w3.org/2000/svg" width="40"
                         height="40" fill="currentColor"
                         className="bi bi-currency-dollar" viewBox="0 0 16 16">
                      <path
                        d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73z"/>
                    </svg>
                  </div>
                  <div>
                    <h3
                      className="md:text-2xl text-xl font-bold text-uppercase">
                      <Trans message={"NO CONTRACT"} />
                    </h3>
                    <p className="text-lg">
                      <Trans message={"All month to month or year to year. Get 2 months free if you go yearly on any storage plan."} />
                    </p>
                  </div>
                </div>
                <div className="flex items-center p-20 mb-20 box">
                  <div className="icon mr-14">
                    <svg xmlns="http://www.w3.org/2000/svg" width="40"
                         height="40" fill="currentColor"
                         className="bi bi-gear-wide-connected"
                         viewBox="0 0 16 16">
                      <path
                        d="M7.068.727c.243-.97 1.62-.97 1.864 0l.071.286a.96.96 0 0 0 1.622.434l.205-.211c.695-.719 1.888-.03 1.613.931l-.08.284a.96.96 0 0 0 1.187 1.187l.283-.081c.96-.275 1.65.918.931 1.613l-.211.205a.96.96 0 0 0 .434 1.622l.286.071c.97.243.97 1.62 0 1.864l-.286.071a.96.96 0 0 0-.434 1.622l.211.205c.719.695.03 1.888-.931 1.613l-.284-.08a.96.96 0 0 0-1.187 1.187l.081.283c.275.96-.918 1.65-1.613.931l-.205-.211a.96.96 0 0 0-1.622.434l-.071.286c-.243.97-1.62.97-1.864 0l-.071-.286a.96.96 0 0 0-1.622-.434l-.205.211c-.695.719-1.888.03-1.613-.931l.08-.284a.96.96 0 0 0-1.186-1.187l-.284.081c-.96.275-1.65-.918-.931-1.613l.211-.205a.96.96 0 0 0-.434-1.622l-.286-.071c-.97-.243-.97-1.62 0-1.864l.286-.071a.96.96 0 0 0 .434-1.622l-.211-.205c-.719-.695-.03-1.888.931-1.613l.284.08a.96.96 0 0 0 1.187-1.186l-.081-.284c-.275-.96.918-1.65 1.613-.931l.205.211a.96.96 0 0 0 1.622-.434zM12.973 8.5H8.25l-2.834 3.779A4.998 4.998 0 0 0 12.973 8.5m0-1a4.998 4.998 0 0 0-7.557-3.779l2.834 3.78zM5.048 3.967l-.087.065zm-.431.355A4.98 4.98 0 0 0 3.002 8c0 1.455.622 2.765 1.615 3.678L7.375 8zm.344 7.646.087.065z"/>
                    </svg>
                  </div>
                  <div>
                    <h3
                      className="md:text-2xl text-xl font-bold text-uppercase">
                      <Trans message={"100% AUTOMATED"} />
                    </h3>
                    <p className="text-lg">
                      <Trans message="Automatically backup your PC, laptop and smartphone. It's good for unlimited devices." />
                    </p>
                  </div>
                </div>
                <div className="flex items-center p-20 mb-20 box">
                  <div className="icon mr-14">
                    <svg xmlns="http://www.w3.org/2000/svg" width="40"
                         height="40" fill="currentColor" className="bi bi-lock"
                         viewBox="0 0 16 16">
                      <path
                        d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2m3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2M5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1"/>
                    </svg>
                  </div>
                  <div>
                    <h3
                      className="md:text-2xl text-xl font-bold text-uppercase">
                      <Trans message={"MULTIPLE LAYERS OF PROTECTION"} />
                    </h3>
                    <p className="text-lg">
                      <Trans message={"Server side, client side and in transit encryption. Security hardening like brute-force detection, Content Security Policy (CSP)."} />
                    </p>
                  </div>
                </div>
                <div className="flex items-center p-20 mb-20 box">
                  <div className="icon mr-14">
                    <svg xmlns="http://www.w3.org/2000/svg" width="40"
                         height="40" fill="currentColor" className="bi bi-phone"
                         viewBox="0 0 16 16">
                      <path
                        d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
                      <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2"/>
                    </svg>
                  </div>
                  <div>
                    <h3
                      className="md:text-2xl text-xl font-bold text-uppercase">
                      <Trans message={"MOBILE APPS"} />
                    </h3>
                    <p className="text-lg">
                      <Trans message={"Auto Backup your smartphones and access your storage for iPhone and Android."} />
                      <br />
                    </p>
                    <div className="flex">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20"
                           height="20" fill="currentColor"
                           className="bi bi-apple mr-4" viewBox="0 0 16 16">
                        <path
                          d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282"/>
                        <path
                          d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516s1.52.087 2.475-1.258.762-2.391.728-2.43m3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422s1.675-2.789 1.698-2.854-.597-.79-1.254-1.157a3.7 3.7 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56s.625 1.924 1.273 2.796c.576.984 1.34 1.667 1.659 1.899s1.219.386 1.843.067c.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758q.52-1.185.473-1.282"/>
                      </svg>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20"
                           height="20" fill="currentColor"
                           className="bi bi-android2" viewBox="0 0 16 16">
                        <path
                          d="m10.213 1.471.691-1.26q.069-.124-.048-.192-.128-.057-.195.058l-.7 1.27A4.8 4.8 0 0 0 8.005.941q-1.032 0-1.956.404l-.7-1.27Q5.281-.037 5.154.02q-.117.069-.049.193l.691 1.259a4.25 4.25 0 0 0-1.673 1.476A3.7 3.7 0 0 0 3.5 5.02h9q0-1.125-.623-2.072a4.27 4.27 0 0 0-1.664-1.476ZM6.22 3.303a.37.37 0 0 1-.267.11.35.35 0 0 1-.263-.11.37.37 0 0 1-.107-.264.37.37 0 0 1 .107-.265.35.35 0 0 1 .263-.11q.155 0 .267.11a.36.36 0 0 1 .112.265.36.36 0 0 1-.112.264m4.101 0a.35.35 0 0 1-.262.11.37.37 0 0 1-.268-.11.36.36 0 0 1-.112-.264q0-.154.112-.265a.37.37 0 0 1 .268-.11q.155 0 .262.11a.37.37 0 0 1 .107.265q0 .153-.107.264M3.5 11.77q0 .441.311.75.311.306.76.307h.758l.01 2.182q0 .414.292.703a.96.96 0 0 0 .7.288.97.97 0 0 0 .71-.288.95.95 0 0 0 .292-.703v-2.182h1.343v2.182q0 .414.292.703a.97.97 0 0 0 .71.288.97.97 0 0 0 .71-.288.95.95 0 0 0 .292-.703v-2.182h.76q.436 0 .749-.308.31-.307.311-.75V5.365h-9zm10.495-6.587a.98.98 0 0 0-.702.278.9.9 0 0 0-.293.685v4.063q0 .406.293.69a.97.97 0 0 0 .702.284q.42 0 .712-.284a.92.92 0 0 0 .293-.69V6.146a.9.9 0 0 0-.293-.685 1 1 0 0 0-.712-.278m-12.702.283a1 1 0 0 1 .712-.283q.41 0 .702.283a.9.9 0 0 1 .293.68v4.063a.93.93 0 0 1-.288.69.97.97 0 0 1-.707.284 1 1 0 0 1-.712-.284.92.92 0 0 1-.293-.69V6.146q0-.396.293-.68"/>
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="flex items-center p-20 mb-20 box">
                  <div className="icon mr-14">
                    <img src="/images/new-landing/aes256.png" alt="" />
                    <img src="/images/new-landing/sslcerti-gb.png" alt="" />
                  </div>
                  <div>
                    <h3
                      className="md:text-2xl text-xl font-bold text-uppercase">
                      <Trans message={"AES 256-BIT ENCRYPTION FACTS"} />
                    </h3>
                    <p className="text-lg">
                      <Trans message={"AES 256-bit encryption is the strongest and most robust encryption standard that is commercially available today. With AES 256-bit encryption being the superior in digital security. The less AES 128-bit encryption has never been cracked. You can rest assure that you are safe with 256-bit AES encryption and SSL/TLS protocols. Your data is always secure at rest and in transit!"} />
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <h2
              className="md:text-3xl text-xl text-uppercase text-center font-bold my-30 pt-20">
              <Trans message={"GET STARTED TODAY WITH A MONTHLY SECURED DATA STORAGE PLAN OR "}/>
              <span className="underline"><Trans message={"GET 2 MONTHS FREE"}/></span>
              <Trans message={"AND GO YEARLY!"} />
            </h2>
            <div className="plans flex md:flex-row flex-col w-full">
              <div className="plan md:w-1/3 left mt-40">
                <div
                  className="plan-title text-center text-2xl font-bold pb-10 border-b">
                  <Trans message={"PERSONAL"} />
                  <img src="/images/new-landing/gb-logo.svg" width="180" alt=""
                       className="mx-auto" />
                </div>
                <div className="plan-price font-bold text-center mb-20">
                  $8.97
                  <span className="md:text-lg">
                    <Trans message={"/MONTH"} />
                  </span>
                </div>
                <div className="plan-features">
                  <div className="item flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24"
                         height="24" fill="currentColor"
                         className="bi bi-person" viewBox="0 0 16 16">
                      <path
                        d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
                    </svg>
                    <span className="font-bold uppercase">
                      <Trans message={"1 Account"} />
                    </span>
                  </div>
                  <div className="item flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24"
                         height="24" fill="currentColor"
                         className="bi bi-database mr-8" viewBox="0 0 16 16">
                      <path
                        d="M4.318 2.687C5.234 2.271 6.536 2 8 2s2.766.27 3.682.687C12.644 3.125 13 3.627 13 4c0 .374-.356.875-1.318 1.313C10.766 5.729 9.464 6 8 6s-2.766-.27-3.682-.687C3.356 4.875 3 4.373 3 4c0-.374.356-.875 1.318-1.313M13 5.698V7c0 .374-.356.875-1.318 1.313C10.766 8.729 9.464 9 8 9s-2.766-.27-3.682-.687C3.356 7.875 3 7.373 3 7V5.698c.271.202.58.378.904.525C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777A5 5 0 0 0 13 5.698M14 4c0-1.007-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1s-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4v9c0 1.007.875 1.755 1.904 2.223C4.978 15.71 6.427 16 8 16s3.022-.289 4.096-.777C13.125 14.755 14 14.007 14 13zm-1 4.698V10c0 .374-.356.875-1.318 1.313C10.766 11.729 9.464 12 8 12s-2.766-.27-3.682-.687C3.356 10.875 3 10.373 3 10V8.698c.271.202.58.378.904.525C4.978 9.71 6.427 10 8 10s3.022-.289 4.096-.777A5 5 0 0 0 13 8.698m0 3V13c0 .374-.356.875-1.318 1.313C10.766 14.729 9.464 15 8 15s-2.766-.27-3.682-.687C3.356 13.875 3 13.373 3 13v-1.302c.271.202.58.378.904.525C4.978 12.71 6.427 13 8 13s3.022-.289 4.096-.777c.324-.147.633-.323.904-.525"></path>
                    </svg>
                    <span className="font-bold uppercase">
                      <Trans message={"1TB Backup"} />
                    </span>
                  </div>
                  <div className="item flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24"
                         height="24" fill="currentColor"
                         className="bi bi-check2-circle mr-8"
                         viewBox="0 0 16 16">
                      <path
                        d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0"></path>
                      <path
                        d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z"></path>
                    </svg>
                    <span className=""><Trans message={"Simple & Hassle Free"} /></span>
                  </div>
                  <div className="item flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24"
                         height="24" fill="currentColor"
                         className="bi bi-check2-circle mr-8"
                         viewBox="0 0 16 16">
                      <path
                        d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0"></path>
                      <path
                        d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z"></path>
                    </svg>
                    <span className=""><Trans message={"View Files Anywhere"} /></span>
                  </div>
                  <div className="item flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24"
                         height="24" fill="currentColor"
                         className="bi bi-check2-circle mr-8"
                         viewBox="0 0 16 16">
                      <path
                        d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0"></path>
                      <path
                        d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z"></path>
                    </svg>
                    <span className=""><Trans message={"Safe & Secure"} /></span>
                  </div>
                  <div className="item flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22"
                         height="22" fill="currentColor"
                         className="bi bi-x-circle mr-8" viewBox="0 0 16 16">
                      <path
                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                      <path
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                    </svg>
                    <span className=""><Trans message={"No Reseller Option Available"} /></span>
                  </div>
                </div>
                <div className="py-20 flex flex-wrap justify-center">
                  <div className="text-center my-8 mx-8">
                    <a
                      href={url+"gb9ff51f"}
                      className="focus-visible:ring text-on-primary bg-primary border border-primary hover:bg-primary-dark hover:border-primary-dark disabled:text-disabled disabled:bg-disabled disabled:border-transparent disabled:shadow-none shadow-md whitespace-nowrap inline-flex align-middle flex-shrink-0 items-center transition-button duration-200 select-none appearance-none no-underline outline-none disabled:pointer-events-none disabled:cursor-default rounded-full justify-center font-semibold text-base h-50 px-24 text-lg uppercase">$8.97/Mo</a>
                  </div>
                  <div className="text-center my-8 mx-8">
                    <a
                      href={url+"gb63afd5"}
                      className="focus-visible:ring text-on-primary bg-primary border border-primary hover:bg-primary-dark hover:border-primary-dark disabled:text-disabled disabled:bg-disabled disabled:border-transparent disabled:shadow-none shadow-md whitespace-nowrap inline-flex align-middle flex-shrink-0 items-center transition-button duration-200 select-none appearance-none no-underline outline-none disabled:pointer-events-none disabled:cursor-default rounded-full justify-center font-semibold text-base h-50 px-24 text-lg uppercase">$89.70/Yr</a>
                    <div className=""><Trans message={"2 Months Free"} /></div>
                  </div>
                </div>
              </div>

              <div className="plan md:w-1/3 middle">
                <div
                  className="plan-title text-center text-3xl font-bold pb-10 border-b">
                  <img src="/images/new-landing/gb-logo-white.svg" width="180" alt=""
                       className="mx-auto" />
                  <Trans message={"FAMILY"} />
                </div>
                <div className="plan-price font-bold text-center mb-20">
                  $9.97
                  <span className="md:text-lg">
                  <Trans message={"/MONTH"} />
                  </span>
                </div>
                <div className="plan-features">
                  <div className="item flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24"
                         height="24" fill="currentColor"
                         className="bi bi-people mr-8" viewBox="0 0 16 16">
                      <path
                        d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"></path>
                    </svg>
                    <span
                      className="font-bold uppercase"><Trans message={"6 SEPARATE ACCOUNTS"} /></span>
                  </div>
                  <div className="item flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24"
                         height="24" fill="currentColor"
                         className="bi bi-database mr-8" viewBox="0 0 16 16">
                      <path
                        d="M4.318 2.687C5.234 2.271 6.536 2 8 2s2.766.27 3.682.687C12.644 3.125 13 3.627 13 4c0 .374-.356.875-1.318 1.313C10.766 5.729 9.464 6 8 6s-2.766-.27-3.682-.687C3.356 4.875 3 4.373 3 4c0-.374.356-.875 1.318-1.313M13 5.698V7c0 .374-.356.875-1.318 1.313C10.766 8.729 9.464 9 8 9s-2.766-.27-3.682-.687C3.356 7.875 3 7.373 3 7V5.698c.271.202.58.378.904.525C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777A5 5 0 0 0 13 5.698M14 4c0-1.007-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1s-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4v9c0 1.007.875 1.755 1.904 2.223C4.978 15.71 6.427 16 8 16s3.022-.289 4.096-.777C13.125 14.755 14 14.007 14 13zm-1 4.698V10c0 .374-.356.875-1.318 1.313C10.766 11.729 9.464 12 8 12s-2.766-.27-3.682-.687C3.356 10.875 3 10.373 3 10V8.698c.271.202.58.378.904.525C4.978 9.71 6.427 10 8 10s3.022-.289 4.096-.777A5 5 0 0 0 13 8.698m0 3V13c0 .374-.356.875-1.318 1.313C10.766 14.729 9.464 15 8 15s-2.766-.27-3.682-.687C3.356 13.875 3 13.373 3 13v-1.302c.271.202.58.378.904.525C4.978 12.71 6.427 13 8 13s3.022-.289 4.096-.777c.324-.147.633-.323.904-.525"></path>
                    </svg>
                    <span className="font-bold uppercase"><Trans message={"6 TB BACKUP"} /></span>
                  </div>
                  <div className="item flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24"
                         height="24" fill="currentColor"
                         className="bi bi-check-all" viewBox="0 0 16 16">
                      <path
                        d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486z"/>
                    </svg>
                    <span className=""><Trans message={"Backup For Unlimited Devices"} /></span>
                  </div>
                  <div className="item flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24"
                         height="24" fill="currentColor"
                         className="bi bi-check-all" viewBox="0 0 16 16">
                      <path
                        d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486z"/>
                    </svg>
                    <span className=""><Trans message={"Priority Support"} /></span>
                  </div>
                  <div className="item flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24"
                         height="24" fill="currentColor"
                         className="bi bi-check-all" viewBox="0 0 16 16">
                      <path
                        d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486z"/>
                    </svg>
                    <span
                      className=""><Trans message={"Easy To Assign And Manage Each Device"} /></span>
                  </div>
                  <div className="item flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24"
                         height="24" fill="currentColor"
                         className="bi bi-check-all" viewBox="0 0 16 16">
                      <path
                        d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486z"/>
                    </svg>
                    <span className=""><Trans message={"Reseller Option Available"} /></span>
                  </div>
                </div>
                <div className="py-20 flex flex-wrap justify-center">
                  <div className="text-center my-8 mx-8">
                    <a
                      href={url+"gbef9a4f"}
                      className="focus-visible:ring text-primary bg-primary-light border border-primary hover:bg-white hover:border-primary-dark disabled:text-disabled disabled:bg-disabled disabled:border-transparent disabled:shadow-none shadow-md whitespace-nowrap inline-flex align-middle flex-shrink-0 items-center transition-button duration-200 select-none appearance-none no-underline outline-none disabled:pointer-events-none disabled:cursor-default rounded-full justify-center font-semibold text-base h-50 px-24 text-lg uppercase">$9.97/Mo</a>
                  </div>
                  <div className="text-center my-8 mx-8">
                    <a
                      href={url+"gbe7d504"}
                      className="focus-visible:ring text-primary bg-primary-light border border-primary hover:bg-white hover:border-primary-dark disabled:text-disabled disabled:bg-disabled disabled:border-transparent disabled:shadow-none shadow-md whitespace-nowrap inline-flex align-middle flex-shrink-0 items-center transition-button duration-200 select-none appearance-none no-underline outline-none disabled:pointer-events-none disabled:cursor-default rounded-full justify-center font-semibold text-base h-50 px-24 text-lg uppercase">$99.97/Yr</a>
                    <div className=""><Trans message={"2 Months Free"} /></div>
                  </div>
                </div>
              </div>

              <div className="plan md:w-1/3 right mt-40">
                <div
                  className="plan-title text-center text-2xl font-bold pb-10 border-b">
                  ENTERPRISE
                  <img src="/images/new-landing/gb-logo.svg" width="180" alt=""
                       className="mx-auto" />
                </div>
                <div className="plan-price font-bold text-center mb-20">
                  $59.97<span className="md:text-lg"><Trans message={"/MONTH"} /></span>
                </div>
                <div className="plan-features">
                  <div className="item flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24"
                         height="24" fill="currentColor"
                         className="bi bi-people mr-8" viewBox="0 0 16 16">
                      <path
                        d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"></path>
                    </svg>
                    <span
                      className="font-bold uppercase"><Trans message={"20 SEPARATE ACCOUNTS"} /></span>
                  </div>
                  <div className="item flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24"
                         height="24" fill="currentColor"
                         className="bi bi-database mr-8" viewBox="0 0 16 16">
                      <path
                        d="M4.318 2.687C5.234 2.271 6.536 2 8 2s2.766.27 3.682.687C12.644 3.125 13 3.627 13 4c0 .374-.356.875-1.318 1.313C10.766 5.729 9.464 6 8 6s-2.766-.27-3.682-.687C3.356 4.875 3 4.373 3 4c0-.374.356-.875 1.318-1.313M13 5.698V7c0 .374-.356.875-1.318 1.313C10.766 8.729 9.464 9 8 9s-2.766-.27-3.682-.687C3.356 7.875 3 7.373 3 7V5.698c.271.202.58.378.904.525C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777A5 5 0 0 0 13 5.698M14 4c0-1.007-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1s-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4v9c0 1.007.875 1.755 1.904 2.223C4.978 15.71 6.427 16 8 16s3.022-.289 4.096-.777C13.125 14.755 14 14.007 14 13zm-1 4.698V10c0 .374-.356.875-1.318 1.313C10.766 11.729 9.464 12 8 12s-2.766-.27-3.682-.687C3.356 10.875 3 10.373 3 10V8.698c.271.202.58.378.904.525C4.978 9.71 6.427 10 8 10s3.022-.289 4.096-.777A5 5 0 0 0 13 8.698m0 3V13c0 .374-.356.875-1.318 1.313C10.766 14.729 9.464 15 8 15s-2.766-.27-3.682-.687C3.356 13.875 3 13.373 3 13v-1.302c.271.202.58.378.904.525C4.978 12.71 6.427 13 8 13s3.022-.289 4.096-.777c.324-.147.633-.323.904-.525"></path>
                    </svg>
                    <span className="font-bold uppercase"><Trans message={"20 TB BACKUP"} /></span>
                  </div>
                  <div className="item flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24"
                         height="24" fill="currentColor"
                         className="bi bi-check2-circle mr-8"
                         viewBox="0 0 16 16">
                      <path
                        d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0"></path>
                      <path
                        d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z"></path>
                    </svg>
                    <span className=""><Trans message={"Backup For Unlimited Devices"} /></span>
                  </div>
                  <div className="item flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24"
                         height="24" fill="currentColor"
                         className="bi bi-check2-circle mr-8"
                         viewBox="0 0 16 16">
                      <path
                        d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0"></path>
                      <path
                        d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z"></path>
                    </svg>
                    <span className=""><Trans message={"Priority Support"} /></span>
                  </div>
                  <div className="item flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24"
                         height="24" fill="currentColor"
                         className="bi bi-check2-circle mr-8"
                         viewBox="0 0 16 16">
                      <path
                        d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0"></path>
                      <path
                        d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z"></path>
                    </svg>
                    <span
                      className=""><Trans message={"Easy To Assign And Manage Each Device"} /></span>
                  </div>
                  <div className="item flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24"
                         height="24" fill="currentColor"
                         className="bi bi-check2-circle mr-8"
                         viewBox="0 0 16 16">
                      <path
                        d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0"></path>
                      <path
                        d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z"></path>
                    </svg>
                    <span className=""><Trans message={"Reseller Option Available"} /></span>
                  </div>
                </div>
                <div className="py-20 flex flex-wrap justify-center">
                  <div className="text-center my-8 mx-8">
                    <a
                      href={url+"cc45086e"}
                      className="focus-visible:ring text-on-primary bg-primary border border-primary hover:bg-primary-dark hover:border-primary-dark disabled:text-disabled disabled:bg-disabled disabled:border-transparent disabled:shadow-none shadow-md whitespace-nowrap inline-flex align-middle flex-shrink-0 items-center transition-button duration-200 select-none appearance-none no-underline outline-none disabled:pointer-events-none disabled:cursor-default rounded-full justify-center font-semibold text-base h-50 px-24 text-lg uppercase">$59.97/Mo</a>
                  </div>
                  <div className="text-center my-8 mx-8">
                    <a
                      href={url+"bcc42d42"}
                      className="focus-visible:ring text-on-primary bg-primary border border-primary hover:bg-primary-dark hover:border-primary-dark disabled:text-disabled disabled:bg-disabled disabled:border-transparent disabled:shadow-none shadow-md whitespace-nowrap inline-flex align-middle flex-shrink-0 items-center transition-button duration-200 select-none appearance-none no-underline outline-none disabled:pointer-events-none disabled:cursor-default rounded-full justify-center font-semibold text-base h-50 px-24 text-lg uppercase">$599.70/Yr</a>
                    <div className=""><Trans message={"2 Months Free"} /></div>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-center my-30">
              <div className="font-bold">
                <Trans message={"30 Days Money Back Guarantee! "} /><br /><Trans message={"Secure 256-bit SSL Encrypted Payment."} />
              </div>
              <img src="/images/new-landing/cc-ico.png" alt="" className="mx-auto" />
            </div>
          </div>
        </div>
        <div className="py-20 bg-primary">
          <div className="container mx-auto px-14">
            <div
              className="md:flex w-full items-center justify-center flex-wpar text-center">
              <div
                className="text-on-primary font-bold md:text-3xl text-lg ">
                <Trans message={"The Best Backup Solutions. Sign Up Today!"} />
              </div>
              <a
                href="https://willf.gotbackup.com/secure-reliable-cloud-backup-packages.php#plan"
                className="focus-visible:ring text-primary bg-primary-light border border-primary hover:bg-transparent hover:border-primary-dark disabled:text-disabled disabled:bg-disabled disabled:border-transparent disabled:shadow-none shadow-md whitespace-nowrap inline-flex align-middle flex-shrink-0 items-center transition-button duration-200 select-none appearance-none no-underline outline-none disabled:pointer-events-none disabled:cursor-default justify-center font-semibold text-base h-50 px-24 text-lg rounded-button mx-14">
                <Trans message={"Signup Today!"} />
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                     fill="currentColor"
                     className="bi bi-chevron-double-right ml-8"
                     viewBox="0 0 16 16">
                  <path fillRule="evenodd"
                        d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708"/>
                  <path fillRule="evenodd"
                        d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708"/>
                </svg>
              </a>
            </div>
          </div>
        </div>
        <footer className="py-20">
          <div className="container mx-auto px-14">

            <div className="flex footer-menu pb-20 mb-20 border-b">
              <div className="column">
                <ul>
                  <li>
                    <a href="#" className="hover:text-primary-dark"><Trans message={"Home"} /></a>
                  </li>
                  <li>
                    <a
                      href="https://willf.gotbackup.com/security-reliable-protection.php"
                      target="_blank" rel="noreferrer"
                      className="hover:text-primary-dark"><Trans message={"Security"} /></a>
                  </li>
                  <li>
                    <a href="https://willf.gotbackup.com/why-gotbackup.php"
                       target="_blank" rel="noreferrer" className="hover:text-primary-dark"><Trans message={"Why us"} /></a>
                  </li>
                  <li>
                    <a
                      href="https://willf.gotbackup.com/reseller-opportunities.php"
                      target="_blank" rel="noreferrer"
                      className="hover:text-primary-dark"><Trans message={"Resellers"} /></a>
                  </li>
                </ul>
              </div>
              <div className="column">
                <ul>
                  <li>
                    <a
                      href="https://willf.gotbackup.com/secure-reliable-cloud-backup-packages.php"
                      target="_blank" rel="noreferrer"
                      className="hover:text-primary-dark"><Trans message={"Packages"} /></a>
                  </li>
                  <li>
                    <a href="https://willf.gotbackup.com/faq.php"
                       target="_blank" rel="noreferrer"
                       className="hover:text-primary-dark"><Trans message={"FAQ"} /></a>
                  </li>
                  <li>
                    <a href="https://willf.gotbackup.com/contact_us.php"
                       target="_blank" rel="noreferrer"  className="hover:text-primary-dark"><Trans message={"Contact us"} /></a>
                  </li>
                  <li>
                    <a
                      href="https://willf.gotbackup.com/secure-reliable-cloud-backup-packages.php#plan"
                      target="_blank" rel="noreferrer"  className="hover:text-primary-dark"><Trans message={"Join now"} /></a>
                  </li>
                </ul>
              </div>
              <div className="column">
                <ul>
                  <li>
                    <a href="/login" target="_blank"
                       className="hover:text-primary-dark"><Trans message={""} />Members Login</a>
                  </li>
                  <li>
                    <a href="https://gbucloud.com/pages/privacy-policy"
                       target="_blank" rel="noreferrer" className="hover:text-primary-dark"><Trans message={"Privacy Policy"} /></a>
                  </li>
                  <li>
                    <a
                      href="https://willf.gotbackup.com/legal/index_gb.html#terms"
                      target="_blank" rel="noreferrer" className="hover:text-primary-dark"><Trans message={"Terms of use"} /></a>
                  </li>
                  <li>
                    <a
                      href="https://willf.gotbackup.com/legal/index_gb.html#earnings"
                      target="_blank" rel="noreferrer" className="hover:text-primary-dark"><Trans message={"Earnings Disclaimer"} /></a>
                  </li>
                </ul>
              </div>
              <div className="column">
                <div className="font-bold mb-8">Global Virtual Opportunities
                </div>
                <p>6701 Guada Coma Drive <br />
                  Schertz, Texas<br />
                  USA, 78154<br />
                  (210) 651-4487</p>
              </div>
            </div>

            <div
              className="items-center justify-between gap-30 text-center text-muted md:flex md:text-left">Copyright
              © 2025 Global Virtual Opportunities Inc, USA. All Rights Reserved
              <div>
                <button type="button"
                        className="focus-visible:ring bg-transparent border-transparent hover:bg-hover disabled:text-disabled disabled:bg-transparent whitespace-nowrap inline-flex align-middle flex-shrink-0 items-center transition-button duration-200 select-none appearance-none no-underline outline-none disabled:pointer-events-none disabled:cursor-default rounded-button justify-center font-semibold text-sm h-36 px-18 capitalize"
                        id=":r3:" aria-expanded="false" aria-haspopup="menu">
                  <svg aria-hidden="true" focusable="false"
                       xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                       data-testid="LanguageOutlinedIcon"
                       className="svg-icon m-auto -ml-4 mr-8 icon-sm"
                       height="1em" width="1em">
                    <path
                      d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm6.93 6h-2.95c-.32-1.25-.78-2.45-1.38-3.56 1.84.63 3.37 1.91 4.33 3.56zM12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2s.06 1.34.14 2H4.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56-1.84-.63-3.37-1.9-4.33-3.56zm2.95-8H5.08c.96-1.66 2.49-2.93 4.33-3.56C8.81 5.55 8.35 6.75 8.03 8zM12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM14.34 14H9.66c-.09-.66-.16-1.32-.16-2s.07-1.35.16-2h4.68c.09.65.16 1.32.16 2s-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95c-.96 1.65-2.49 2.93-4.33 3.56zM16.36 14c.08-.66.14-1.32.14-2s-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z"></path>
                  </svg>
                  English
                  <svg aria-hidden="true" focusable="false"
                       xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                       data-testid="KeyboardArrowDownOutlinedIcon"
                       className="svg-icon m-auto -mr-4 ml-8 icon-sm"
                       height="1em" width="1em">
                    <path
                      d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path>
                  </svg>
                </button>
              </div>
            </div>

          </div>
        </footer>
      </div>
    </Fragment>
  );
}

function HeroHeader({
  content: {
    headerTitle,
    headerSubtitle,
    headerImage,
    headerImageOpacity,
    actions,
    headerOverlayColor1,
    headerOverlayColor2,
  },
}: ContentProps) {
  const {trans} = useTrans();

  let overlayBackground = undefined;

  if (headerOverlayColor1 && headerOverlayColor2) {
    overlayBackground = `linear-gradient(45deg, ${headerOverlayColor1} 0%, ${headerOverlayColor2} 100%)`;
  } else if (headerOverlayColor1) {
    overlayBackground = headerOverlayColor1;
  } else if (headerOverlayColor2) {
    overlayBackground = headerOverlayColor2;
  }

  return (
    <header
      className="relative mb-14 h-500 md:mb-60 md:h-screen"
      style={{background: overlayBackground}}
    >
      <div
        data-testid="headerImage"
        className="absolute inset-0 z-10 bg-cover bg-1/2 bg-no-repeat md:bg-fixed"
        style={{
          backgroundImage: `url(${headerImage})`,
          opacity: headerImageOpacity,
        }}
      />
      <div className="relative z-20 flex h-full flex-col">
        <Navbar
          color="transparent"
          className="flex-shrink-0"
          menuPosition="homepage-navbar"
        />
        <div className="mx-auto flex max-w-850 flex-auto flex-col items-center justify-center px-14 text-center text-white">
          {headerTitle && (
            <h1
              className="text-4xl font-medium md:text-5xl"
              data-testid="headerTitle"
            >
              <Trans message={headerTitle} />
            </h1>
          )}
          {headerSubtitle && (
            <div
              className="mt-30 text-lg md:mt-10 md:text-xl"
              data-testid="headerSubtitle"
            >
              <Trans message={headerSubtitle} />
            </div>
          )}
          <div className="mt-30 flex min-h-50 gap-20">
            <CtaButton
              item={actions?.cta1}
              variant="raised"
              color="primary"
              size="lg"
              radius="rounded-full"
              data-testid="cta1"
              className="min-w-180"
            />
            <CtaButton
              item={actions?.cta2}
              variant="text"
              color="paper"
              size="lg"
              radius="rounded-full"
              data-testid="cta2"
            />
          </div>
        </div>
      </div>
      {/*<IconButton*/}
      {/*  size="lg"*/}
      {/*  className="absolute bottom-5 left-0 right-0 z-30 mx-auto text-white max-md:hidden"*/}
      {/*  elementType="a"*/}
      {/*  aria-label={trans({message: 'View features'})}*/}
      {/*  href="#primary-features"*/}
      {/*>*/}
      {/*  <KeyboardArrowDownIcon />*/}
      {/*</IconButton>*/}
    </header>
  );
}

interface CtaButtonProps extends ButtonProps {
  item?: MenuItemConfig;
}
function CtaButton({item, ...buttonProps}: CtaButtonProps) {
  if (!item?.label) return null;
  const Icon = item.icon ? createSvgIconFromTree(item.icon) : undefined;
  return (
    <Button
      elementType={item.type === 'route' ? Link : 'a'}
      href={item.action}
      to={item.action}
      startIcon={Icon ? <Icon /> : undefined}
      {...buttonProps}
    >
      <Trans message={item.label} />
    </Button>
  );
}

function PrimaryFeatures({content}: ContentProps) {
  return (
    <div
      className="landing-container items-stretch gap-26 md:flex"
      id="primary-features"
    >
      {content?.primaryFeatures?.map((feature, index) => (
        <div
          key={index}
          className="mb-14 flex-1 rounded-2xl px-24 py-36 text-center shadow-[0_10px_30px_rgba(0,0,0,0.08)] md:mb-0"
          data-testid={`primary-root-${index}`}
        >
          <MixedImage
            className="mx-auto mb-30 h-128"
            data-testid={`primary-image-${index}`}
            src={feature.image}
          />
          <h2
            className="my-16 text-lg font-medium"
            data-testid={`primary-title-${index}`}
          >
            <Trans message={feature.title} />
          </h2>
          <div
            className="text-md text-[0.938rem]"
            data-testid={`primary-subtitle-${index}`}
          >
            <Trans message={feature.subtitle} />
          </div>
        </div>
      ))}
    </div>
  );
}

function SecondaryFeatures({content}: ContentProps) {
  return (
    <div className="landing-container">
      {content?.secondaryFeatures?.map((feature, index) => {
        const isEven = index % 2 === 0;
        return (
          <div
            key={index}
            data-testid={`secondary-root-${index}`}
            className={clsx(
              'mb-14 py-16 md:mb-80 md:flex',
              isEven && 'flex-row-reverse',
            )}
          >
            <img
              src={feature.image}
              className="mr-auto w-580 max-w-full rounded-lg shadow-[0_10px_30px_rgba(0,0,0,0.08)]"
              data-testid={`secondary-image-${index}`}
              alt=""
            />
            <div className="ml-30 mr-auto max-w-350 pt-30">
              <small
                className="mb-16 text-xs font-medium uppercase tracking-widest text-muted"
                data-testid={`secondary-subtitle-${index}`}
              >
                <Trans message={feature.subtitle} />
              </small>
              <h3
                className="py-16 text-3xl"
                data-testid={`secondary-title-${index}`}
              >
                <Trans message={feature.title} />
              </h3>
              <div className="h-2 w-50 bg-black/90" />
              <div
                className="my-20 text-[0.938rem]"
                data-testid={`secondary-description-${index}`}
              >
                <Trans message={feature.description} />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

function BottomCta({content}: ContentProps) {
  return (
    <div
      className="relative bg-[#2B2B2B] bg-no-repeat py-70 text-center text-on-primary md:bg-fixed"
      style={{backgroundImage: `url("${content.footerImage}")`}}
      data-testid="footerImage"
    >
      <h2
        className="mx-auto max-w-620 text-3xl font-normal"
        data-testid="footerTitle"
      >
        <Trans message={content.footerTitle} />
      </h2>
      {content.footerSubtitle && (
        <p
          className="mx-auto mt-50 max-w-620 text-2xl font-normal"
          data-testid="footerSubtitle"
        >
          <Trans message={content.footerSubtitle} />
        </p>
      )}
      <CtaButton
        item={content.actions?.cta3}
        size="lg"
        variant="outline"
        color="paper"
        className="mt-50 block"
        data-testid="cta3"
      />
    </div>
  );
}
