import React from "react";
import {SocialProfile} from "@common/auth/social-profile";
import {Button} from "@common/ui/buttons/button";
import {Trans} from "@common/i18n/trans";
import {
  useStartGoogleImport
} from "@common/import/requests/start-google-import";
import {useImportStatus} from "@common/import/queries/use-import-status";
import {IllustratedMessage} from "@common/ui/images/illustrated-message";
import {SvgImage} from "@common/ui/images/svg-image/svg-image";
import preloaderSvg from "@app/drive/drive-page/preloader.svg";

export function ImportGoogleFiles({user}:{user:SocialProfile}) {
  const query = useImportStatus();

  if (query.isLoading) {
    return (
      <IllustratedMessage
        className="mt-40"
        image={<SvgImage src={preloaderSvg} />}
      />
    );
  } else if (query.status !== 'success' || !query.data.importStatus) {
    return (<StartImportButton />);
  }

  const status = query.data.importStatus;
  return (
    <div>
      <div><Trans message={'Start time:'} /> {(new Date(status.created_at)).toLocaleDateString()}</div>
      <div><Trans message={'Files and folders total count:'} /> {status.total_count}</div>
      <div><Trans message={'Files and folders uploaded:'} /> {status.ready_count}</div>
      {status.finished
        ? (<div><Trans message={'Finished time:'} /> {(new Date(status.updated_at)).toLocaleDateString()}</div>)
        : <div><Trans message={'In progress'} /></div>}
      {status.error && (<div><Trans message={'Error:'} /> {status.error}</div>)}
    </div>
  );

}

function StartImportButton() {
  const startGoogleImport = useStartGoogleImport()

  return (
    <Button
      variant="flat"
      color="primary"
      onClick={() => {
        startGoogleImport.mutate();
      }}
      className={"ms-5 center-block"}
    >
      <Trans message="Start Import" />
    </Button>
  );
}
