import {Trans} from "@common/i18n/trans";
import {Button} from "@common/ui/buttons/button";
import React, { useState } from "react";
import {useGooglePicker} from "@common/import/handleOpenPicker";


function Picker() {
  const [handleOpenPicker, loadingPicker] = useGooglePicker();

  return (
    <div>
      <Button
        variant="flat"
        color="primary"
        onClick={() => handleOpenPicker()}
        disabled={loadingPicker} // Disable button while loading
        className="ms-5 center-block"
      >
        {loadingPicker ? <Trans message="Loading..." /> : <Trans message="Click here to pick what you want to import from google" />}
      </Button>
    </div>
  );
}

export default Picker;
