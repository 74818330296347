import useDrivePicker from 'react-google-drive-picker';
import { getBootstrapData } from '@common/core/bootstrap-data/use-backend-bootstrap-data';
import {apiClient, queryClient} from '@common/http/query-client';
import { toast } from '@common/ui/toast/toast';
import {
  ImportGoogleFilesPayload,
  useImportFiles
} from '@common/import/requests/import-file';
import {useForm} from "react-hook-form";
import {useState} from "react";
import {message} from "@common/i18n/message";
import {useSocialLogin} from "@common/auth/requests/use-social-login";

export const useGooglePicker = () => {
  const [openPicker] = useDrivePicker();
  const settings = getBootstrapData();
  const form = useForm<ImportGoogleFilesPayload>();
  const importFiles = useImportFiles(form);
  const [loadingPicker, setLoadingPicker] = useState(false);
  const {connectSocial} = useSocialLogin();

  const handleOpenPicker = async (isRetry = false):Promise<void> => {
    try {
      setLoadingPicker(true);

      const response = await apiClient.post('import/google/refresh-token');
      const token = response.data.token;

      openPicker({
        clientId: settings.google?.client_id ?? '',
        developerKey: settings.google?.dev_key ?? '',
        appId: settings.google?.app_id ?? '',
        token: token && isJson(token) ? JSON.parse(token).access_token : token,
        showUploadView: false,
        showUploadFolders: false,
        supportDrives: true,
        multiselect: true,
        setIncludeFolders: false,
        setSelectFolderEnabled: false,
        callbackFunction: (data) => {
          console.log(data);
          if (data.action === 'cancel') {
            console.log('User clicked cancel/close button');
          } else if (data.action === 'picked') {
            importFiles.mutate(data);
          }
        },
      });
    } catch (error: any) {
      if (error.response.status === 403 && !isRetry) {
        const e = await connectSocial("google");
        if (e?.status === 'SUCCESS') {
          queryClient.invalidateQueries({queryKey: ['users']});
          toast(message('Enabled google account'));
          return handleOpenPicker(true);
        }
      } else if (error?.response) {
        toast.danger(error?.response.data.message);
      } else {
        toast.danger('Can\'t start import.');
        console.error('Unexpected error:', error?.message);
      }
    } finally {
      setLoadingPicker(false);
    }
  };

  return [handleOpenPicker, loadingPicker, setLoadingPicker] as const;
};

function isJson(item: any) {
  let value = typeof item !== "string" ? JSON.stringify(item) : item;
  try {
    value = JSON.parse(value);
  } catch (e) {
    return false;
  }

  return typeof value === "object" && value !== null;
}
