import {useMutation} from '@tanstack/react-query';
import {UseFormReturn} from 'react-hook-form';
import {BackendResponse} from "@common/http/backend-response/backend-response";
import {toast} from "@common/ui/toast/toast";
import {onFormQueryError} from "@common/errors/on-form-query-error";
import {apiClient} from "@common/http/query-client";
import {message} from "@common/i18n/message";
import {
  getBootstrapData
} from "@common/core/bootstrap-data/use-backend-bootstrap-data";

interface Response extends BackendResponse {
  success: string;
}

export interface ImportGoogleFilesPayload {
  action?: string;
  docs?: {};
}

export function useImportFiles(form: UseFormReturn<ImportGoogleFilesPayload>) {
  return useMutation({
    mutationFn: (props: ImportGoogleFilesPayload) => ImportFiles(props),
    onSuccess: () => {
      toast(message('Import started'));
    },
    onError: r => onFormQueryError(r, form),
  });
}

function ImportFiles(payload: ImportGoogleFilesPayload): Promise<Response> {
  let url = getBootstrapData().settings.base_url;
  const storage = getBootstrapData().user?.storage;
  if (storage) {
    url = storage.upload_url;
  }
  if (getBootstrapData().api_web_token) {
    apiClient.defaults.headers.common['Authorization'] = 'Bearer '+getBootstrapData().api_web_token;
  }

  const tempCookie = document.cookie;
  document.cookie = `XSRF-TOKEN=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;

  return apiClient
    .post((url ? url + '/api/v1/' : '') + `import/google/add-import-files`, payload)
    .then(r => r.data);
}
